import React from 'react';
import './Projects.scss';
import gendersdg from '../../Images/maxresdefault.jpg'
import women1 from '../../Images/woman1.jpeg';
import women2 from '../../Images/woman2.jpg';
import women3 from '../../Images/woman3.jpg';
import img1 from '../../Images/poverty.png';
import img2 from '../../Images/E_SDG goals_icons-individual-rgb-08.png';
import img3 from '../../Images/Sustainable_Development_Goal_13.png';
import img4 from '../../Images/3zero.png';
import img6 from '../../Images/Sustainable_Development_Goal_3.png'
import initiative1 from '../../Images/3zero1 (1).jpg';
import initiatives2 from '../../Images/threezero.png';
import initiatives3 from '../../Images/3zero 3.png';
import microhealth1 from '../../Images/microhealth1.jpg';
import microhealth2 from '../../Images/Microhealth2.jpg';
import microhealth3 from '../../Images/microhealth3.png';

const Projects = () => {
    return (
        <>
      
 <br />
        <br />
        <br />
        <br />
       
        <div className='all-project'>
         
        </div>
 
          <section className='projects'>
       
          
              <div className='gender'>
              <h3 style={{textAlign: 'center' , marginBottom: '4rem',  color: '#2f2f72', fontWeight: '700'}}>Women Empowerment Project</h3>
                <img width="35%"src={gendersdg} alt="" />
              
                </div>
            <div className='gender-equality'>
              
           <div>
           <p>We are working together with Zhumka Bangladesh (NGO) to support poor women for skills development, income generating activity, and micro entrepreneurship activity. Already we have around 5,000 women who received technical training and trying to engage themselves in income generating activity. We are aiming to provide necessary support to those women for their empowerment in the society. </p>
           <br />
              <div className='women-projects'>
               <img height="100%" width="100%" src={women1} alt="" />
                <img width="100%" src={women2} alt="" />
             <img width="100%"  src={women3} alt="" />
            
            </div>
           </div>
            </div>
            
 <div className='zero-initiatives'>
 <h3 style={{textAlign: 'center', marginBottom: '4rem',  color: '#2f2f72', fontWeight: '700'}}>Three ZERO Initiative </h3>
 <div className='three-zero'>
       <img width="90%" src={img1} alt="" />
       <img  width="90%" src={img2} alt="" />
       <img  width="90%" src={img3} alt="" />
       <img  width="90%" src={img4} alt="" />
   </div>
  
     <div>
        <p>The 3ZERO Club is an initiative towards achieving the Nobel Peace Laureate Professor Muhammad Yunus’s vision of creating a world of three zeros — zero net carbon emission, zero wealth concentration for ending poverty, and zero unemployment by unleashing entrepreneurship in all. Each 3ZERO Club is formed with a fixed number of 05 young persons between the age of 12 and 35 years. The age difference between the oldest and youngest members within a Club cannot be more than seven years. Each Club is headed by a person with the title of Key Person and assisted by a deputy known as Deputy Key Person. Members at the time of the registration are known as Founding Members, the Key Person as the Founding Key Person, the deputy as the Founding Deputy Key person. We are working as “3Z SUPPORT ORGANIZATIONS” to provide any kind of assistance to 3ZERO Clubs registered with 3Z Global Centre. Clubs, Circles and Networks can approach the 3Z Global Centre to get connected with appropriate 3Z Support Organisations. Currently we are supporting 4 3ZERO Clubs from Uddog o Uddokta, Bangladesh. Also we are working “Social Business Academia Network”, Japan to organize 3ZERO Leadership Challenge (3ZLC) program. </p>
        <br />
        <div className='women-projects'>
               <img height="100%" width="100%" src={initiative1} alt="" />
                <img width="100%" src={initiatives3} alt="" />
             <img width="100%"  src={initiatives2} alt="" />
            
            </div>
     </div>
 </div>
 <div className='micro-healths'>
 <h3 style={{textAlign: 'center', marginBottom: '4rem',  color: '#2f2f72', fontWeight: '700'}}>Micro Healthcare Entrepreneurship </h3>
 <div className='micro-health'>
       <img width="90%" src={img6} alt="" />
       <img  width="90%" src={img2} alt="" />
    
   </div>
 
        <p className='micro-text'>Micro Healthcare Entrepreneurship (MHE) is an initive by Kyushu University, Japan and Grameen Communications, Bangladesh. Uddog o Uddokta is one of the supporting partner for this initiative. We are providing specilize training, mentoring, funding support to create healcare entreprenurs in rural and urban areas. These entrepremnurs will able to work with Portable Health Clinic digital health system to proviude eassy and affordable orimary healthcare servcies for unreached communities. As on January 2023 we have organzied three workshops and we have provided training to around 70 prospective health entreprenurs. </p>
        <br />
        <div className='women-projects'>
               <img height="100%" width="100%" src={microhealth1} alt="" />
                <img width="100%" src={microhealth2} alt="" />
             <img height="110%" width="110%"  src={microhealth3} alt="" />
            
            </div>
    
 </div>
  

        </section>
       
       
        </>
      
    );
};

export default Projects;