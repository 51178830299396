import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Instruments.css';
import logo from '../../Images/consulting.png';
import img from '../../Images/research.png';
import img1 from '../../Images/training.png';
import img2 from '../../Images/funding.png';


const Instruments = () => {

    return (
    

<div className="middle-items">
            <h1 style={{color: '#323D4A'}} className="fw-bold mt-5"> <span className="">Our Services</span> </h1>
            <Container className="my-5 py-5">
  <Row>
    <Col xs>
    <div className="instruments">    
        <h3 className="my-2 instruments-name">RESEARCH</h3>
            <img className="img-style" width="75%" src={img} alt="" />
            
        </div>
    </Col>
    <Col xs={{ order: 12 }}>
        <div className="instruments">
        <h3 className="my-2 instruments-name">TRAINING</h3>
            <img className="img-style" width="75%" src={img1} alt="" />
            
        </div>
    </Col>
    <Col xs={{ order: 1 }}>
    <div className="instruments">
             <h3 className="my-3 instruments-name">CONSULTING</h3>
            <img className="img-style" width="80%" src={logo} alt="" />
            
        </div>
    </Col>
    <Col xs={{ order: 1 }}>
    <div className="instruments">
    <h3 className="my-3 instruments-name">FUNDING</h3>
            <img className="img-style" width="80%" src={img2} alt="" />
            
        </div>
    </Col>
  </Row>
</Container>
        </div>
    );
};

export default Instruments;