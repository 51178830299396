import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer-style">
           
           <Container>
  <Row>
    <Col xs>
        <div className="footer-styling">
            <h2 className="fw-bolder text-white">Services</h2>
            <p className="fw-bold text-white my-4">Research</p>
            <p className="fw-bold text-white my-4">Training</p>
            <p className="fw-bold text-white my-4">Consulting</p>
            <p className="fw-bold text-white my-4">Funding</p> 
        </div>
    </Col>
    <Col xs={{ order: 12 }} className="footer-styling">
        <h2 className="fw-bolder text-white">Contact</h2>
        <p className=" text-white my-2">Phone:  +88-019-1893-8029
</p>
        <p className= "text-white my-2">Email: contact@uddogouddokta.org ,</p>
        <p className=" text-white my-2">info.uubd@gmail.com</p>
        <p className=" text-white my-2">Web: www.uddogouddokta.org</p>
        <p className=" text-white my-2">Address: C-4 (6TH Floor), 849/3 Begum Rokeya shoroni
Mirpur, Dhaka-1216, Bangladesh
</p>
    </Col>
    <Col xs={{ order: 1 }}>
        <h2 className="fw-bolder text-white">Socials</h2>
        <p className="fw-bold text-white"><a className='socials-link' target={"_blank"} href="https://www.facebook.com/uddogouddokta">Facebook</a>  </p>
        <p className="fw-bold text-white"><a className='socials-link' target={"_blank"} href="https://www.instagram.com/uu_bd_official/">Instagram</a></p>
        <p className="fw-bold text-white"><a className='socials-link' target={"_blank"} href="https://www.linkedin.com/company/uddog-o-uddokta/">LinkedIn</a></p>
        <p className="fw-bold text-white"><a className='socials-link' target={"_blank"} href="https://twitter.com/UddogoUddoktaBD">Twitters</a></p>
    </Col>
  </Row>

</Container>
<div className="mt-5">
<p className="text-white footer-bottom">Copyright <img width="1%" src="https://o.remove.bg/downloads/d1ae6f8a-5594-4bc7-86f0-45961b852fa7/001_2688246-5b916f32c9e77c0025c0cc9c-removebg-preview.png" alt="" /> 2022. All Rights Reserved</p>
</div>
     
        </div>
    );
};

export default Footer;