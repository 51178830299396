import React from 'react';
import "./Vision.css"
import vision from '../../Images/vision-bulb-word-cloud-business-concept-60193290-removebg-preview.png'
import mission from '../../Images/mission.png';

const Vision = () => {
    return (
        <div>
           <div className="about-styling">
          
          <div className="about-us py-5 vision-styling">
              <div>
                  <img width={'100%'} src={vision} alt="" />
              </div>
              <div className="my-5 py-5 container">
                  <h4 style={{color: "#323D4A"}} className="fw-bolder fs-2">VISION</h4>
                  <p className="text-dark-50 vision container">
                  Congregate entrepreneurial enthusiasts towards achieving sustainable accomplishment.


</p>
              </div>
          </div>
          
      
          <div className="about-all py-5 vision-styling">
              
              <div className="py-5 container">
                  <h4 style={{textAlign: 'left', color: "#323D4A"}} className="fw-bolder fs-3">MISSION</h4>
                  <p className="text-dark-50 vision container"> 
                  <li>To assist on building knowledge and skills about entrepreneurship 
</li>
                  <li>To enrich the capability of entrepreneurs through research, training , counseling  & relevant activities </li>
                  <li>To ensure the proper combination of theoretical study and field level operation 
</li>
                  <li>To solve contemporary social issues assuring creative social businesses 

</li>
                  <li>To provide all sort of possible  supports to convert failure entrepreneurs into success</li>
                
</p>
              </div>
              <div className="my-2 py-5">
                  <img width={'100%'} src={mission} alt="" />
              </div>
          </div>
      </div>
        </div>
    );
}

export default Vision;