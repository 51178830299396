import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Welcome.css';
import logo from '../../Images/graduatetimg.png';
import logo2 from '../../Images/trophy.png';
import logo3 from '../../Images/pie-chart.png';

const Welcome = () => {
    return (
    <div data-aos="fade-right" className='heading-page'>


<Container className='welcome-page-bd'>
      <Row>
        <h6 style={{textAlign: 'justify', color: '#323D4A' , fontWeight: '700'}}>Uddog o Uddokta <br />

A Social Business Initiative
</h6>
        <Col style={{textAlign: 'justify'}} className=''>UDDOG O UDDOKTA is a social organization/social business initiative. Our objective is to solve various social problems and to achieve SDGs through entrepreneurship activity. We provide Research, Training, and Consulting services. We believe that donation/charity can be helpful for one time, but entrepreneurship model can be a sustainable way to solve any problem. </Col>
      </Row>
    </Container>   
    </div>
        
    );
};

export default Welcome;