import React from 'react';
import img from '../../Images/music44.png'
import img1 from '../../Images/musicschool1.png'
import './About.css'
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import caroimg from '../../Images/caro3.jpeg';
import caroimg1 from '../../Images/caro7.jpeg';
import caroimg2 from '../../Images/caro4.jpeg';
import shirin from '../../Images/shirin-uubd.jpeg';
import OurTeam from './OurTeam/OurTeam';

const About = () => {
    return (
        <div className="top-items h-50">
            <Carousel className='caro-banner'>
  <Carousel.Item className=''>
    <img
      className="caroimg d-block w-100"
      src={caroimg}
      alt="First slide"
    />
    <Carousel.Caption>
 
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="caroimg d-block w-100"
      src={caroimg1}
      alt="Second slide"
    />

    <Carousel.Caption>

    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="caroimg d-block w-100"
      src={caroimg2}
      alt="Third slide"
    />

    <Carousel.Caption>

    </Carousel.Caption>
  </Carousel.Item>
</Carousel> 
<Container className='my-5'>
      <Row>
        <Col>
        <h3 style={{color: '#323D4A'}}>History</h3>
            <div>
                <div className="row">
                  <div className="col-md-2">
                     <h4 style={{color: '#323D4A'}}> 2012 </h4>
                  </div>
                  <div className="col-md-10 history-uubd">
                    <p>: We have started our journey on 01 January 2012 by organizing some youth leaders aiming to become student entrepreneur. We developed the concept and working strategy and started our entrepreneurial preparation. We not only thought about ourselves to become entrepreneur, also we though to create more entrepreneurs like us.  We have started our project “Uddog o Uddokta” to work for entrepreneurship development and we registered under LESD Career & Business Solution on 02 May 2012</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                  <h4 style={{color: '#323D4A'}}>2015 </h4>
                  </div>
                  <div className="col-md-10 history-uubd">
                    <p>: On January 07, 2015, we changed we updated our company from Proprietorship business to Limited company and we named our company as LBS Management Consultancy Pvt. Ltd. We expanded our activities in many areas related to research, training, and consulting. “Uddog o Uddokta” still was a project to support students and new entrepreneurs. 
</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <h4 style={{color: '#323D4A'}}> 2022 </h4>
                  </div>
                  <div className="col-md-10 history-uubd">
                    <p>: On June 16, 2022, we registered “Uddog o Uddokta Ltd.” as separate entity as a social business model to work for the social development through entrepreneurship. We our divided our activities in four major categories: Research, Training, Consulting and Funding. During last 10 years we have supported 1000+ youths for entrepreneurship training and consulting. Now we are focusing internationalization of our initiatives. </p>
                  </div>
                </div>
               
           
            </div>
        </Col>
      </Row>
    </Container> 




    <h2 style={{color: '#323D4A'}} className='my-5'>Meet Our Managing Director and CEO
</h2>
    <Container className='my-5 py-5'>
      <Row>
      
        <Col>
        <div>
        <div>
      <img width={'70%'} src={shirin} alt="" />
     </div>
        </div>
        </Col>
        <Col>
        <div>


  <h3 style={{color: '#4000ff', margin: '25px', textAlign: 'justify'}}>Jahan Ruma Akhtar Shirin</h3>
          <p style={{textAlign: 'justify', color: '#323D4A'}}>
          Over 10 years of professional experiences in International Trade, Education and Development Sectors. She is an Entrepreneurship Trainer, Consultant and Researcher. She is also a Certified Master Trainer of the ILO’s Flagship Global Business Management Program called SIYB (Start and Improve Your Business) and a certified Trainer of UNICEF’s UPSHIFT (a flagship program of UNICEF for youth and adolescent development with social innovation and entrepreneurship) programme. 

Professionally, she is an entrepreneur. Beside “Uddog o Uddokta”, she has also established an English Medium School and a Food Chain in Bangladesh. 

Ms. Shirin has completed her graduation and post-graduation in Business Administration majoring in Accounting & Finance and HRM. She has also received many business and leadership trainings from several national and international organizations. 

          </p>
        <p style={{textAlign: 'justify'}}><span style={{color: '#323D4A', fontWeight: '700', fontSize: '26'}}>Email: </span> <span style={{color: '#323D4A'}}></span> ceo@uddogouddokta.org </p>
 </div>
        </Col>
      </Row>
   
    </Container>
        
        <Container>
         <OurTeam></OurTeam>
        </Container>
        </div>

    );
};

export default About;