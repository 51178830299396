import React from 'react';
import { Carousel } from 'react-bootstrap';
import './TopItems.css'
import img from '../../Images/caro1.jpeg';

import img2 from '../../Images/caro5.jpeg';
import img1 from '../../Images/Uddog_o_Uddokta_KyushuUniversity.JPG';
import img3 from '../../Images/slider2.PNG'


const TopItems = () => {
    return (
        <div className="top-items">
            <Carousel className='responsive-for-mbl'>
  <Carousel.Item className=''>
    <img
      className="d-block w-100 "
      src={img3}
      alt="First slide"
    />

  </Carousel.Item>
  <Carousel.Item className=''>
    <img
      className="d-block w-100"
      src={img1}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item className=''>
    <img
      className="d-block w-100"
      src={img2}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item className=''>
    <img
      className="d-block w-100"
      src={img}
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>
            
        </div>
    );
};

export default TopItems;