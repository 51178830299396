
import Instruments from '../Instruments/Instruments';
import Mission from '../Mission/Mission';
import TopItems from '../TopItems/TopItems';
import Vision from '../Vision/Vision';
import Welcome from '../Welcome/Welcome';
import './Home.css'

const Home = () => {

    return (
        <div className="home-style">      
            <TopItems></TopItems>
            <Welcome></Welcome>
            <Vision></Vision>
            <Mission></Mission>
           
            <Instruments></Instruments>
                  
        </div>
    );
};

export default Home;