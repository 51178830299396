import React from 'react';
import blv from '../../Images/blv.png'

const Mission = () => {
    return (
        <div>
         <div>
           <div className="about-styling">
          
          <div className="about-us py-5 vision-styling">
              <div>
                  <img width={"100%"} src={blv} alt="" />
              </div>
              <div className="my-5 py-5 container">
                  <h4 style={{textAlign: 'left', color: "#323D4A"}} className="fw-bolder fs-2">What we believe?</h4>
                  <p className="text-dark-50 vision container"> 
                  <li>“ENTREPRENEURSHIP” can be the best tool to solve social problems

</li>
                  <li>Entrepreneurship initiative must be sustainable and social objective driven</li>
                  <li>We can achieve ZERO Unemployment goal, which is one of the major causes of poverty and other social problems

</li>
                  <li>Women empowerment, gender equality can be achieved swiftly through women entrepreneurship 

</li>
                  <li>Sustainable development could be achieved within target through social businesses 

</li>
                
</p>
              </div>
          </div>
          
      
        
      </div>
        </div>
        </div>
    );
};

export default Mission;