import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import uubd from '../../Images/new uubd 2.png';

import { NavLink } from 'react-router-dom';
import './Header.css';


const Header = () => {
  // window.onscroll = function(){
  //       if(window.scrollY >= 200 || window.scrollY >= 500){
  //           document.getElementById('scroll-navbar-width').style.width= '100%';
  //           document.getElementById('scroll-navbar-width').style.boxShadow= '0px 5px 10px rgba(0, 0, 0, 0.493)'     
  //           ;
       
  //         }
       
  //         //  else if(window.scrollY <= 200){
  //         //       document.getElementById('scroll-navbar-width').style.width= '90%';
                
  //         // }
  //         // if(window.scrollY >= 700){
  //         //   document.getElementById('scroll-navbar-width').style.boxShadow= '0px 5px 10px white';
  //         //   ;
  //         // }
  //         else if(window.scrollY <= 200){
  //           document.getElementById('scroll-navbar-width').style.boxShadow= '0px 0px 0px rgba(0, 0, 0, 0.493)';
  //           ;


  //     }
       
  //   }

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // }; 
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

    return (
        <div className="header-style">

<Navbar collapseOnSelect expand="lg" className="nav-styling" fixed='top'>
  <Container className='nav-height'>
  <Navbar.Brand className="navbar-style" to="/home"><img width={"150px"} className='logo-height' src={uubd} alt=""/> </Navbar.Brand>
  <Navbar.Toggle className='mbl-bg' aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse className='mbl-bg' id="responsive-navbar-nav">
          

    <Nav className="me-auto">

  
    
    </Nav>
    <Nav className=''>
    <NavLink  activeStyle={{
    fontWeight: "bold",
    color: "#1a759f"
  }} className="nav-style" to="/home">HOME</NavLink>
    <NavLink  activeStyle={{
    fontWeight: "bold",
    color: "#1a759f"
  }} className="nav-style" to="/about">ABOUT US</NavLink>
    <NavLink  activeStyle={{
    fontWeight: "bold",
    color: "#1a759f"
  }} className="nav-style" to="/NewServices">SERVICES</NavLink>
    <NavLink  activeStyle={{
    fontWeight: "bold",
    color: "#1a759f"
  }} className="nav-style" to="/projects">PROJECTS</NavLink>  
    <NavLink  activeStyle={{
    fontWeight: "bold",
    color: "#1a759f"
  }} className="nav-style" to="/news">NEWS</NavLink>  
    <NavLink  activeStyle={{
    fontWeight: "bold",
    color: "#1a759f"
  }} className="nav-style" to="/events">EVENTS</NavLink>  
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
        </div>
    );
};

export default Header;