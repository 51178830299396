import React from 'react';
import './News.scss';
import news from '../../Images/News-Photo-UUBD.png';
import shirin from '../../Images/shirinvisited2.jpg';
import leadership from '../../Images/3zleaderhip-challenge.jpg';
import partnership from '../../Images/partnership-3z.jpg';
import microhealth from '../../Images/microhealth.jpg';
import three3z from '../../Images/threezero.png';
import { FacebookButton, FacebookCount } from "react-social";
import {FacebookShareButton} from "react-share";
import {FacebookIcon} from "react-share";
import {LinkedinShareButton} from "react-share";
import {  LinkedinIcon } from 'react-share';


const News = () => {
    let url = "https://uddogouddokta.org/news";
    return (
        <div className='news-portal'>
           <div className='news'>
              <div className='single-news'>
                    <img src={news} alt="" />
                    <div className='news-content'>
                    <p style={{color: 'gray', fontSize: '18px'}}>Dec 4, 2022 </p>
                    <div>
                    <FacebookShareButton  url="https://uddogouddokta.org/news"
                    quote={"News"}
                    
                    >     
                        <FacebookIcon className='social-icon' widths="10%" logoFillColor="white" round={true}>
                  
                        </FacebookIcon>
                    
                    </FacebookShareButton>
                    <LinkedinShareButton  url="https://uddogouddokta.org/news"
                    quote={"News"}
                    
                    >     
                        <LinkedinIcon className='social-icon' widths="10%" logoFillColor="white" round={true}>
                  
                        </LinkedinIcon>
                    
                    </LinkedinShareButton>
                    </div>
                    
                    </div>
                 
                   <h2>Entrepreneurship Career Design: Unleash the Horizon

</h2>  
<p>The Robert T. Huang Entrepreneurship Center of Kyushu University (QREC) organized eight special lectures to help the student think out of box. After the covid 19 pandemic we are trying to reshape our world. Students has many concerns about their future career as well as they are the leader for future. To support the students at Kyushu University these special lectures organized and invite the lecturers from the real-life professional field. So that, students can learn something new out of textbook. Among the eight lectures one lectures was focused on entrepreneurial career design. Ms. Jahan Ruma Akhtar Shirin, CEO, Uddog o Uddokta, Bangladesh, delivered this lecture. Lecture titled as "Entrepreneurship Career Design: Unleash the Horizon".  Students from the Bachelor, Master's, and Ph.D. joined this lecture. The objective of the lecture was "to design a future career with an entrepreneurial mindset by exploring the own ability and social needs of the surroundings keeping in mind the impact of the pandemic".  She covered how we can identify our personal SWOT, how we can drive into deep to any issue by using 5WHYs method, how we can design SMART Goal and good action plan. Students also shared their views regarding their career vision, challenges they are afraid of, and their plan as well. <br /> 
 <br />
 Following this lecture Ms. Shirin also delivered another lecture at an open house event of the QREC on "Social Entrepreneurship". During this lecture she explained the social entrepreneurship journey in Bangladesh, she mentioned the social entrepreneurship/social business activities of BRAC, Grameen, and Uddog o Uddokta. 
 
Students expressed their gratitude for this kind of sessions. Faculties also shared that they are interested to organise these kinds of lectures more in near future.
<br />
<br />
The QREC was established as the backdrop of Kyushu University’s growing need to work systematically on entrepreneurship education that encourages students to challenge themselves to create new values, as well as MOT (Management of Technology) education, which shows students how they can transfer their research results and technologies into society’s benefits.
<br />
<br />
"Uddog o Uddokta" is a social organization which work for solving social issues through entrepreneurship. It is started in 2012, and currently, they are working as a registered social organization in Bangladesh and Japan. Also working to expand activities worldwide by involving others who are interested to work for sustainable solutions to ensure a better world!

</p>
              </div>
              <div>
              <div className='many-news'>
                <img  src={shirin} alt="" />
              <h6 style={{textAlign: "left", margin: "0.5rem"}}>Our CEO Visited To Japan</h6>
             </div>
              <div className='many-news'>
                <img src={microhealth} alt="" />
          <h6 style={{textAlign: "left", margin: "0.5rem"}}>Micro Health Entrepreneurship Workshop</h6>
              </div>
              <div className='many-news'>
                <img src={partnership} alt="" />
          <h6 style={{textAlign: "left", margin: "0.5rem"}}>New partnership with the 3Z Global Center with everyone.</h6>
              </div>
              <div className='many-news'>
                <img src={three3z} alt="" />
          <h6 style={{textAlign: "left", margin: "0.5rem"}}>3ZLC Leadership Challenge</h6>
              </div>
              </div>
         
            </div> 
        </div>
    );
};

export default News;