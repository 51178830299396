import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import './NewServices.css'

const NewServices = () => {
    return (
        <div className="top-items">
        <Carousel className='responsive-for-mbl'>
<Carousel.Item className=''>
<img
  className="caroimg d-block w-100 "
  src={"https://images.unsplash.com/photo-1501290741922-b56c0d0884af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cmVzZWFyY2h8ZW58MHx8MHx8&w=1000&q=80"}
  alt="First slide"
/>
<Carousel.Caption>

</Carousel.Caption>
</Carousel.Item>

<Carousel.Item className=''>
<img
  className="caroimg d-block w-100"
  src={"https://www.hubspot.com/hubfs/Consultant%20using%20fast%20tips%20to%20win%20consulting%20clients.jpg"}
  alt="Third slide"
/>

<Carousel.Caption>

</Carousel.Caption>
</Carousel.Item>
<Carousel.Item className=''>
<img
  className="caroimg d-block w-100"
  src={"https://www.happoldfoundation.org/wp-content/uploads/2016/05/small-grants.jpg"}
/>

<Carousel.Caption>

</Carousel.Caption>
</Carousel.Item>
</Carousel>

<Container className='my-5'>
      <Row>
        <Col>
        <h3 style={{textAlign: 'justify', color: 'navy', fontWeight: "700"}}>Research</h3>
        <h6 style={{textAlign: 'justify', color: '#323D4A'}}>To ensure you get the most out of your business research choices, we offer the following services and solutions for our customers:</h6>
        <p style={{textAlign: 'justify', color: '#323D4A'}}>
            <li> <span className='fw-bold'> Client Research - </span>We will help you stay on top your customer's mind by determining what your consumers expect from your business. This way you can create relevant experience for your client.</li>
            <li> <span className='fw-bold'> Business Market Research - </span>We are well-equipped to provide clients with accurate and high-quality business market research services. We leverage the latest market research tools and technologies to deliver world-class services.
</li>
            <li> <span className='fw-bold'> Business Development Support - </span>We will nurture relationships with your audience, stakeholders, and partners by offering value adds. One step at-a-time we will forge strong ties with people whose interests align with yours.
</li>
            <li> <span className='fw-bold'> Trend analysis - </span>We will use prediction technology and analyze past and present data to determine the best strategy that will give you a leg up in business.
</li>
            <li> <span className='fw-bold'> Perceptual Mapping Services - </span>Our perceptual mapping services will help you understand how your brand is perceived by your audience. We will give you complete insights into how you can enhance your brand performance.
</li>
            <li> <span className='fw-bold'>Primary & Secondary Research - </span>We have the bandwidth and the professionals to handle your research requirement. We will fetch you data from the past as well as the present to help you make powerful decisions.
</li>
            <li> <span className='fw-bold'>Industry Profiling - </span>Our research analysts will do complete competitor analysis, trend analysis, customer analysis, etc. before you venture into an industry to ensure success of your venture.

</li>
            <li> <span className='fw-bold'>Business Competitor Analysis - </span>We provide comprehensive competitive business analysis services as well, providing in-depth reviews of your competitors and their resources and what strengths and weaknesses they have compared to yours

</li>
            <li> <span className='fw-bold'>Social Media Monitoring - </span> Social media monitoring services help your companies to get the desired information about your customers and keep you updated about the latest developments. Our services help you monitor, analyze, and generate key insight and reports which can help you optimize your marketing efforts in the right direction.

</li>
            <li> <span className='fw-bold'>Competitive Business Analysis Services - </span>Competitive business analysis services can help you understand your competitors better and stay ahead of them at all times. Our team can provide you with the required services such as forward and backward integration, economies of scale analysis, strength of key players, competitor profiling, SWOT and PESTEL analysis, etc.

</li>
            <li> <span className='fw-bold'>Company Profiling Services - </span>Our company profiling services are powered by an extremely talented and skilled team of research analysts and marketers who take care of all your needs. Our market research team can also provide you with the right insight which will aid you in creating the right marketing message for your targeted audience.

</li>
            <li> <span className='fw-bold'>Predictive Financial Analytics Services - </span>Your business will be provided with a plethora of important predictive financial analytics services that will ensure that you are able to make better decisions for the future of your company.

</li>
            <li> <span className='fw-bold'>Benchmarking Analytics Services - </span>Benchmarking analytics is a data-driven process by which we help our clients comprehend their capabilities in definite and competitive terms. We help our clients compare their performance and processes against their peers and identify opportunities.

</li>
            <li> <span className='fw-bold'>Benchmarking Services - </span> We provide a wide variety of benchmarking services to clients from across the world and from virtually every industry. We gather data on your competitors and analyze it to derive insights into how you can better steer your company to obtain maximum productivity and profitability.



</li>
            <li> <span className='fw-bold'>Competitor Benchmarking Services - </span>With our competitor benchmarking services, we help you gain insights into how exactly your business is comparing as compared to your peers. We help you come up with strategies, detect opportunities and threats, and gain in-depth knowledge about your industry.


</li>
            <li> <span className='fw-bold'>Business Benchmarking Services - </span>We provide highly robust and accurate business benchmarking services that help you understand how your business is performing as compared to your peers. With our services, you get insights into opportunities for improvement and cost savings.


</li>
            <li> <span className='fw-bold'>IT Benchmarking Services -  </span>We provide comprehensive IT benchmarking services that enable you to optimize your IT processes and make well-informed decisions to steer your IT strategy in the right direction.


</li>
            <li> <span className='fw-bold'>Data Benchmarking Services -  </span>With our data benchmarking services, we enable business owners and investment managers to maximize the operational efficiency, performance, and scalability of their data. We also help to limit the regulatory and operational risks while providing full control over the data.


</li>
            <li> <span className='fw-bold'>Open-End Coding Market Research Services -</span>Open-ended questions are an important aspect of qualitative market research since they allow you to elicit detailed information and valuable insights. Our coding expert will then prepare a list of answers that will be assigned a code for the client's approval.

</li>
            <li> <span className='fw-bold'>Integrated Business Planning Services -  </span>Our objective is to ensure that the IBP process has achieved a Class A level of effectiveness. This means that it is deeply established as a method that is achieving the desired results. We conduct an assessment and confirm that the process has reached this level of effectiveness.


</li>
            <li> <span className='fw-bold'>Online Business Research -  </span>We can develop a comprehensive report of your business and the opportunities currently available to it through online research of competitors, target audience behaviors and current buzz


</li>
            <li> <span className='fw-bold'>Business Report Writing -  </span>We can produce detailed, industry specific business reports, both for the purpose of clearly defining the findings from our research and for further reporting to executives


</li>
            <li> <span className='fw-bold'>Database Creation Services - </span>We can create fully customized databases to collate and analyze key data points gathered during the research phase, both financially and qualitatively


</li>
            <li> <span className='fw-bold'>Business Research Survey -</span>We can produce and distribute surveys that measure the likelihood a prospect would become a customer, measure brand recall and more


</li>
            <li> <span className='fw-bold'>Risk Assessments - </span>We can provide in-depth risk assessments based on a number of factors such as your international marketing campaigns, the potential of expansion and your current operations


</li>
        </p>
        </Col>
      </Row>
    </Container>
    <div className='training-section'>
    <Container className='my-5'>
      <Row>
        <Col>
        <h3 style={{textAlign: 'justify', color: 'navy', fontWeight: '700'}}>Training</h3>
        <p style={{textAlign: 'justify', color: '#323D4A'}}>We are providing following traings for entrepreneurship development:</p>
        <p style={{textAlign: 'justify', color: '#323D4A'}}>
            <li>Basics of Entrepreneurship
</li> 
            <li>Digital Entrepreneurship </li> 
            <li>Becoming an Entrepreneur</li> 
            <li>Entrepreneurship: From Business Idea to Action	</li> 
            <li>Entrepreneurship Essentials</li> 
            <li>Entrepreneurship: How To Start A Business From Business Idea	</li> 
            <li>How to get a Startup Fund</li> 
            <li>How to Build a Startup</li> 
            <li>Strategic Business Management </li> 
            <li>
Business in International Market </li> 
<li>Business Legal Issues </li>
<li>
Basic Accounting and Finance for Business </li>
<li>IT Essentials </li>
<li>Web Development</li>
<li>Cyber and Information Security</li>
<li>Graphic and Animations </li>
<li>Digital Marketing
</li>

        </p>
        </Col>
      </Row>
    </Container>
    </div>
   
    <Container className='my-5'>
      <Row>
        <Col>
        <h5 style={{textAlign: 'justify', color: 'navy', fontWeight: '700'}}>Consulting</h5>
        <p style={{textAlign: 'justify', color: '#323D4A'}}>We are offering following management consulting services for the business sustainability:
</p>
        <p style={{textAlign: 'justify'}}>
            <li>Feasibility study
</li> 
            <li>Business Plan  </li> 
            <li>Strategic Plan</li> 
            <li>Change Management 
	</li> 
            <li>Product Development</li> 
            <li> Business  Collaboration Policy Formulation 
</li> 
            <li>Risk Analysis and Management</li> 
            <li>How to Build a Startup</li> 
            <li>Competitive Analysis and Strategy Formulation 
And other ondemand base services 
</li> 
            <li>
Business in International Market </li> 
<li>Business Legal Issues </li>
<li>
Basic Accounting and Finance for Business </li>
<li>Tax and VAT Management in Business</li>

<li>Lean Six Sigma, Kaizen, and Problem Solving</li> 
<li>Business Software Development </li> 
<li>Web Applications</li> 
<li>Mobile Applications</li> 
<li>E-commerce Management </li>
        </p>
        </Col>
      </Row>
    </Container>
    <Container className='my-5'>
      <Row>
        <Col>
        <h5 style={{textAlign: 'justify', color: 'navy', fontWeight: '700'}}>Funding</h5>
        <p style={{textAlign: 'justify', color: '#323D4A'}}>We have some enlisted angel investors and opther funding networks, if you need fund for your startup please consult us directly for further process.</p>
        
        </Col>
      </Row>
    </Container>
    <Container className='my-5'>
      <Row>
        <Col>
   
        <p style={{textAlign: 'justify', color: '#323D4A'}}>For any kind of services please write to us: 
        <br />
         conatct@uddogouddokta.org, info.uubd@gmail.com</p>
        
        </Col>
      </Row>
    </Container>
    </div>
    );
};

export default NewServices;