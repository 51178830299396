import React from 'react';
import './Events.scss'
import event from '../../Images/Event-Photo-UUBD.jpg'

const Events = () => {
    return (
        <div className='Event-background'>

<br />
<br />
<br />
<br />
<h5 style={{textAlign: "center", fontWeight: '900', fontSize:'32px', color: 'white', marginTop: '15px'}}>Events</h5>

            <section className='events'>
         
             <div className='event-content'>
             <p> <span style={{fontWeight: "700"}}> Date: </span>
January 3-4, 2022 <br />
<span style={{fontWeight: "700"}}> Location: </span>
Grameen Telecom Bhaban, Mirpur-1, Dhaka, Bangladesh <br />
<span style={{fontWeight: "700"}}>Title: </span>
3rd Micro Healthcare Entrepreneurship <br />
<span style={{fontWeight: "700"}}>Nature: </span>
 Workshop <br />
<span style={{fontWeight: "700"}}>Objective: </span>
 To provide basic training on entrepreneurship and Portable Health Clinic digital health system. <br />
<span style={{fontWeight: "700"}}>Target Group: </span>
Certified Health Asistants in Bangladesh <br />
<span style={{fontWeight: "700"}}> Click here: </span>
<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSd7UHI5lNb9WbVUx8OmTEkj7biy2vxuakq7Yet16431ox7F_A/closedform">Event Link</a>

</p> 

             </div>
               
<img style={{marginRight: '18rem'}} width="90%" src={event} alt="" />
         
            </section>
        </div>
    );
};

export default Events;