import React from 'react';
import { Row, Col } from 'react-bootstrap';
import tajul from '../../../Images/tajul-islam.jpg';
import hasalalmahmud from '../../../Images/Hasan-Al-Mamum-UUBD.jpeg';
import aliAzam from '../../../Images/Ali-Azam-UUBD.jpeg';
import zehadul from '../../../Images/Zeaul Huda Hemal.jpeg';
import monir from '../../../Images/Monir Hossain (1).jpg';
import shakhayet from '../../../Images/Md Sakayet Hossain (1).jpg';
import tahmid from '../../../Images/Tahmid Tazwar Khan (1).jpg';
import Arnob from '../../../Images/Jobayer Hossain Chowdhury (1).jpg';
import Peeya from '../../../Images/Nadia Binte Peeya (1).jpg';

import './OurTeam.scss';
import facebook from '../../../Images/icons8-facebook-48.png'



const OurTeam = () => {
    return (
        <section className='our__team'>
            <div>
                <h1 className='team'>Our Team</h1>
            </div>
<div className="projects__items">
        <div className="projects__item">
            <div>
            <img src={aliAzam} alt="My Project"/>
            </div>
          
    
            <div className="projects__btns">
         
            <a target="_blank"  href="" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank"  href="https://www.facebook.com/ali.azam.313371" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            
            </div>
            <div className='team-contents'>
                <h5>S M Ali Azam</h5>
                <p>Management, Social Work, Team Management</p>
            </div>
        </div>
   
        <div className="projects__item">
            <div>
            <img src={hasalalmahmud} alt="My Project"/>
            </div>
          
            <div className="projects__btns">
               <a target="_blank" href="https://www.linkedin.com/in/halmamun/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/hamamun1985" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/forhad.bipu/" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Md. Hasan Al Mamun</h5>
                <p>HRM, Soft Skills, Leadership</p>
            </div>
        </div>
        <div className="projects__item">
            <div>
            <img src={zehadul} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="https://www.linkedin.com/in/hemalzeaulhuda/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/HEMAL.ZEAULHUDA" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Md. Zeaul Huda Hemal</h5>
                <p>Marketing, Entrepreneurship, Public Relation</p>
            </div>
        </div>
        <div className="projects__item">
            <div>
            <img src={tajul} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i  className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Md Tajul Islam</h5>
                <p>Admin, Marketing, Logistics</p>
            </div>
        </div>
    
        <div className="projects__item">
            <div>
            <img className='item-img' src={monir} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="https://www.linkedin.com/in/monir-hossain-b387674b/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Md. Monir Hossain </h5>
                <p>Finance, Accounting, Project Management</p>
            </div>
        </div>
        <div className="projects__item">
            <div>
            <img className='item-img' src={shakhayet} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="https://www.linkedin.com/in/md-sakayet-hossain-102588138/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/mdsakayet.hossain.180" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Md. Sakayet Hossain</h5>
                <p>Project Management, Admin, Soft Skills</p>
            </div>
        </div>
        <div className="projects__item">
            <div>
            <img className='item-img' src={tahmid} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="https://www.linkedin.com/in/tahmid-tazwar-khan-057a4719a/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/tahmid.tazwar.967" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Tahmid Tazwar</h5>
                <p>Softskills</p>
            </div>
        </div>
        <div className="projects__item">
            <div>
            <img className='item-img' src={Arnob} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="https://www.linkedin.com/in/md-jobayer-hossain-chowdhury-168220209/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/arnob.chowdhury.9889" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Md Jobayer Hossain Chowdhury</h5>
                <p>Web Development, Software Development, Data Science</p>
            </div>
        </div>
        <div className="projects__item">
            <div>
            <img className='item-img' src={Peeya} alt="My Project"/>
            </div>
           
            <div className="projects__btns">
               <a target="_blank"  href="https://www.linkedin.com/in/nadia-binte-rahman-%E3%83%8A%E3%83%87%E3%82%A3%E3%82%A2-086104187/" className="projects__btn">
                <i className="fa fa-linkedin"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/peeya.rahman" className="projects__btn">
                <i className="fab fa-facebook"></i>
               </a>
               <a target="_blank"  href="#!" className="projects__btn">
                <i className="fab fa-twitter"></i>
               </a>
            </div>
            <div className='team-contents'>
                <h5>Nadia Bintee Rahman Peeya</h5>
                <p>Web Development, Software Development, Data Science</p>
            </div>
        </div>

        </div>
        </section>
        
    );
};

export default OurTeam;